<template>
    <div id="setting-layout" class="container-fluid">
        <mdb-row>
            <mdb-col sm="12" md='12' lg="4" class="mb-4">
                <mdb-list-group class="z-depth-1" >
                    <mdb-list-group-item 
                        v-if="$gate.hasPermission(['view-any::setting'])"
                        icon="th-large" :class="($router.currentRoute.fullPath === '/parametres')? 'white-text' :'text-primary'" 
                        :action="true" @click.native="$router.push({path:'/parametres'})" tag="a" 
                        :active="($router.currentRoute.fullPath === '/parametres')">  <span class="ml-2"> Généralité</span></mdb-list-group-item>
                    <mdb-list-group-item 
                        v-if="$gate.hasPermission(['view-any::setting'])"
                        :class="($router.currentRoute.fullPath === '/parametres/invoices')? 'white-text' :'text-primary'" 
                        icon="file-invoice-dollar" :action="true" 
                        @click.native="$router.push({path:'/parametres/invoices'})"  tag="a" 
                        :active="($router.currentRoute.fullPath === '/parametres/invoices')">  <span class="ml-2 pl-1"> Facturation</span></mdb-list-group-item>
                    <mdb-list-group-item 
                        v-if="$gate.hasPermission(['view-any::setting'])"
                        :class="($router.currentRoute.fullPath === '/parametres/banks')? 'white-text' :'text-primary'" 
                        icon="dollar-sign" :action="true" @click.native="$router.push({path:'/parametres/banks'})" 
                         tag="a" :active="($router.currentRoute.fullPath === '/parametres/banks')">  <span class="ml-2 pl-2"> Informations Bancaires</span> </mdb-list-group-item>
                    <mdb-list-group-item 
                        v-if="$gate.hasPermission(['view-any::setting'])"
                        :class="($router.currentRoute.fullPath === '/parametres/auth-emecef')? 'white-text' :'text-primary'" 
                        icon="key" :action="true" @click.native="$router.push({path:'/parametres/auth-emecef'})" 
                        tag="a" :active="($router.currentRoute.fullPath === '/parametres/auth-emecef')">  <span class="ml-2 "> Jeton de Connexion</span> </mdb-list-group-item>
                    <mdb-list-group-item 
                        v-if="$gate.hasPermission(['view-any::setting'])"
                        :class="($router.currentRoute.fullPath === '/parametres/subscriptions')? 'white-text' :'text-primary'" 
                        icon="credit-card" :action="true" @click.native="$router.push({path:'/parametres/subscriptions'})" 
                        tag="a" :active="($router.currentRoute.fullPath === '/parametres/subscriptions')">  <span class="ml-2 ">Abonnements</span> </mdb-list-group-item>
                    <mdb-list-group-item 
                        v-if="$gate.hasPermission(['view-any::setting'])"
                        :class="($router.currentRoute.fullPath === '/parametres/subscriptions')? 'white-text' :'text-primary'" 
                        icon="cloud" :action="true" @click.native="$router.push({path:'/parametres/storage'})" 
                        tag="a" :active="($router.currentRoute.fullPath === '/parametres/storage')">  <span class="ml-2 ">Stockage</span> </mdb-list-group-item>
                    <mdb-list-group-item 
                        v-if="$gate.hasPermission(['view-any::setting'])"
                        :class="($router.currentRoute.fullPath === '/parametres/notifications')? 'white-text' :'text-primary'" 
                        icon="bell" :action="true" @click.native="$router.push({path:'/parametres/notifications'})" 
                        tag="a" :active="($router.currentRoute.fullPath === '/parametres/notifications')">  <span class="ml-2 ">Notifications</span> </mdb-list-group-item>
                </mdb-list-group>
            </mdb-col>
            <mdb-col sm="12" md="12" lg="8">
                <router-view></router-view>
            </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import {
    mdbRow,mdbCol,
    mdbListGroup, mdbListGroupItem,
    
} from 'mdbvue'
export default {
    name: 'setting-layout',
    components:{
        mdbRow,mdbCol,
        mdbListGroup, mdbListGroupItem,
    },
    data () {
        return {
        // 
        }
    },
    created (){ 
    },
    mounted () {
        // 
    },
    updated () {
        // 
    }
}
</script>

<style scoped>

</style>
